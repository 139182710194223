<template>
  <div class="container4">
    <div class="leftmap">
      
      <div class="plugin">
          <div :class="{'pbtn':true, 'activebtn':'vector'=== currentMapLayer.type}" @click="toggleMapType('vector')">矢量地图</div>
          <div :class="{'pbtn':true, 'activebtn':'satellite'=== currentMapLayer.type}" @click="toggleMapType('satellite')">影像地图</div>
      </div>
      <l-map
        ref="map"
        style="width: 100%; height: 100%; z-index: 10"
        :zoom="12"
        :center="center"
      >
          <l-tile-layer :url="currentMapLayer.url" ></l-tile-layer>
         <l-tile-layer :url="currentMapLayer.texturl" :attribution="currentMapLayer.attribution"></l-tile-layer>

        <!-- 地块点 -->
        <l-marker
          v-if="detail.lat_gaode && detail.lng_gaode"
          :name="detail.name"
          :icon="icon(detail.usecate)"
          :lat-lng="[detail.lat_gaode, detail.lng_gaode]"
          :offset="[0, 20]"
        >
        </l-marker>
        <!-- 地库多边形 -->
        <l-polygon
          v-for="item in polygonpath"
          :key="item.id"
          :lat-lngs="item.path"
          :color="item.strokeColor"
          :fill-color="item.filecolor"
          :fill-opacity="0.6"
        >
        </l-polygon>

        <l-marker
          v-for="item in pointsList"
          :key="item.id"
          :icon="icon()"
          :lat-lng="[item.lat_gaode, item.lng_gaode]"
          :offset="[8, 0]"
          :visible="item.visible"
        >
          <l-tooltip
            :options="{
              permanent: true,
              interactive: true,
              offset: [4, -16],
              className: 'color',
            }"
          >
            <div class="tooltipInnerBox">
              <img cLass="icon" :src="item.imgurl" />
              <div class="title">
                {{ item.name }}
              </div>
            </div>
          </l-tooltip>
        </l-marker>
      </l-map>

      <ul class="drawing-panel">
        <!-- <el-button
          type="primary"
          size="mini"
          class="draw-btn"
          @click="myDis.open()"
          >开启测距</el-button
        > -->
        <!-- <el-button type="primary" size="mini" class="draw-btn" @click="myDis.close()">关闭测距</el-button> -->
      </ul>
    </div>

    <div class="rightlist">
      <div class="toplist">
        <div class="toptitle">风险类别</div>
        <div class="topcon">
          <div class="topitem" v-for="item in showdata" :key="item.cateid">
            <el-switch class="switch" v-model="item.checked"></el-switch>
            <div class="iconbox">
              <img class="iconimg" :src="item.iconurl" alt="" />
              <div class="iconcatename">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottomlist">
        <div class="bottomlistinner">
          <div class="btitle">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >地点名称
            </el-checkbox>
          </div>
          <el-checkbox-group
            v-model="checkedCities"
            @change="handleCheckedCitiesChange"
          >
            <div class="bitem" v-for="point in pointsList" :key="point.id">
              <el-checkbox :label="point.id">{{ point.name }}</el-checkbox>
            </div>
          </el-checkbox-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LPolyline,
  LTooltip,
  LPolygon,
  LIcon,
} from "vue2-leaflet";
import { vectorMapLayer,satelliteMapLayer,leafletmapurl,leafletmaptexturl,leafletmapyunxuanurl, isgaode } from "@/settings.js";

import { icon } from "leaflet";

import { getDetail, downloadFileByCate, zhoubianData } from "../../api/data";

const wenwu = require("@/assets/icon/wenwu.png");
const gaoya = require("@/assets/icon/gaoya.png");
const wuranyun = require("@/assets/icon/wuran.png");
const bingz = require("@/assets/icon/bingz.png");
const jingguan = require("@/assets/icon/jingguan.png");
const shangye = require("@/assets/icon/shangye.png");
const yiliao = require("@/assets/icon/yiliao.png");
const jiaoyu = require("@/assets/icon/jiaoyu.png");

const icontype7 = "@/assets/icon/wenwu.png";
const icontype8 = "@/assets/icon/gaoya.png";
const icontype9 = "@/assets/icon/wuran.png";
const icontype10 = "@/assets/icon/bingz.png";
const icontype3 = "@/assets/icon/jingguan.png";
const icontype4 = "@/assets/icon/shangye.png";
const icontype5 = "@/assets/icon/yiliao.png";
const icontype6 = "@/assets/icon/jiaoyu.png";

export default {
  name: "FrontendPcBigdataLandlayout",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LPolyline,
    LPolygon,
    LIcon,
    LTooltip,
  },

  props: {
    cate: 1, //1 周边配套 2宗地风险点
  },
  data() {
    return {
      polygonpath: [],

      centerpoint: [32.1212, 120.1212],
      crs: L.CRS.EPSG3857, // 使用的是EPSG:3857坐标系
      url: leafletmapurl,
      attribution: "",

       //矢量地图对象
       vectorMapLayer:vectorMapLayer ,
      //卫星地图数据对象
      satelliteMapLayer: satelliteMapLayer,
      //当前地图图层
      currentMapLayer:{url:'',texturl:'',attribution:'',type:'vector'},

      staticAnchor: [16, 37],
      center: [0, 0],
      zoom: 18,
      detail: {},
      pointsList: [],

      checkAll: false,
      checkedCities: [1, 2],

      isIndeterminate: true,

      id: 0,
      map: {},
      detail: {},
      myDis: {},
      imglist: [],

      showdata: [],
      catepeitao: [
        {
          cateid: 3,
          name: "景观资源",
          checked: true,
          iconurl: jingguan,
        },
        {
          cateid: 4,
          name: "商业资源",
          checked: true,
          iconurl: shangye,
        },
        {
          cateid: 5,
          name: "医疗资源",
          checked: true,
          iconurl: yiliao,
        },
        {
          cateid: 6,
          name: "教育资源",
          checked: true,
          iconurl: jiaoyu,
        },
      ],

      catedata: [
        {
          cateid: 7,
          name: "文物古迹",
          checked: true,
          iconurl: wenwu,
        },
        {
          cateid: 8,
          name: "高压线",
          checked: true,
          iconurl: gaoya,
        },
        {
          cateid: 9,
          name: "污染源",
          checked: true,
          iconurl: wuranyun,
        },
        {
          cateid: 10,
          name: "殡葬区",
          checked: true,
          iconurl: bingz,
        },
      ],
    };
  },

  watch: {
    showdata: {
      handler(nv, ov) {
        if (!nv.some((item) => item.checked)) {
          //若全部隐藏时候则显示第一个
          nv[0].checked = true;
        }
        this.getCateData()
      },
      deep: true,
    },

    cname(v) {
      var that = this;

      that.getPointData();
      that.getPolygonData();
    },

    polygondata: {
      handler(v) {
        // console.log('==data==', v)

        v.map((item) => {
          this.handlerData(item.set);
        });
      },
      deep: true,
    },
  },

  computed: {
    city_plate_id: {
      get() {
        let a = this.$store.state.currentLandData.cid;
        return a;
      },
    },

    shi: {
      get() {
        let a = this.$store.state.currentLandData.cid;
        return a;
      },
    },

    currentLandData: {
      get() {
        return this.$store.state.currentLandData;
      },
    },
  },

  mounted() {
    if (this.cate === 1) {
      this.showdata = this.catepeitao;
    } else {
      this.showdata = this.catedata;
    }

    if (this.$route.params.id) {
      this.id = this.$route.params.id;
    }
    this.getMapDetail().then((res) => {
      // this.drawPoint(this.detail)
      // this.createOverlay(this.detail)
      this.getImgList();

      //获取分类数据
      this.getCateData();
    });
  },
  
  created() {
     this.currentMapLayer = this.vectorMapLayer;
     this.currentMapLayer.type = 'vector'
  },


  methods: {
     toggleMapType(type) {
      if (type === 'vector') {
        this.currentMapLayer = this.vectorMapLayer;
        this.currentMapLayer.type = 'vector'
      } else if (type === 'satellite') {
        this.currentMapLayer = this.satelliteMapLayer;
        this.currentMapLayer.type = 'satellite'
      }
    },


    icon(num) {
      if (num == null || num == "") {
        return icon({
          iconUrl: require("@/assets/opcity.png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      } else {
        return icon({
          iconUrl: require("@/assets/type" + num + ".png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      }
    },

    // 打开测距功能
    openDistanceTool(e) {
      alert(1);

      const { distanceTool } = this;
      distanceTool && distanceTool.open();
    },

    //获取分类数据
    getCateData() {
      let t = [];
      if (this.cate === 2) {
        //周边风险点
        this.catedata.map((item) => {
          if (item.checked === true) {
            t.push(item.cateid);
            // return item.cateid
          }
        });
        t = t.toString();
      } else {
        //周边配套
        this.catepeitao.filter((item) => {
          if (item.checked === true) {
            t.push(item.cateid);
          }
        });
        t = t.toString();
      }

      const map = this.$refs.map.mapObject; // 获取地图实例
      const bounds = map.getBounds(); // 获取当前视图的边界

      // 如果需要经纬度值，可以使用bounds对象的_northEast和_southWest属性
      const northEast = bounds._northEast;
      const southWest = bounds._southWest;
      //获取边界条件
      const params = {
        ll_lng: southWest.lng, // 经度 左下角
        ll_lat: southWest.lat, // 纬度 左下角
        ru_lng: northEast.lng, // 经度 右上角
        ru_lat: northEast.lat, // 纬度 右上角
        type: t,
        is_gaode:isgaode,
      };

      zhoubianData(params).then((res) => {
        this.pointsList = this.handleImg(res.data);
        console.log("this.pointsList", this.pointsList);

        this.checkedCities = this.pointsList.map((item) => item.id);
      });
    },

    handleImg(data) {
      const res = data.map((item) => {
        item.imgurl = require("@/assets/icon/icontype" + item.type + ".png");
        return item;
      });
      return res;
    },

    handleCheckAllChange(val) {
      // alert(val)
      if (val === false) {
        this.pointsList = this.pointsList.map((item) => {
          item.visible = false;
          return item;
        });
      } else {
        this.pointsList = this.pointsList.map((item) => {
          item.visible = true;
          return item;
        });
      }
      this.checkedCities = val ? this.pointsList.map((item) => item.id) : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.pointsList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.pointsList.length;

      const currentlist = this.pointsList.map((item) => {
        if (this.checkedCities.indexOf(item.id) !== -1) {
          item.visible = true;
        } else {
          item.visible = false;
        }
        return item;
      });
      this.pointsList = currentlist;
    },
    getImgList() {
      downloadFileByCate({ pid: this.id, category_id: 9 }).then((res) => {
        this.imglist = res.data;
      });
    },

    getMapDetail() {
      var that = this;
      return new Promise(function (resolve, reject) {
        getDetail({ id: that.currentLandData.id, is_gaode:isgaode }).then((res) => {
          that.detail = res.data;

          that.handerPolygondata(res.data.id, res.data.set_gaode);

          that.center = [res.data.lat_gaode, res.data.lng_gaode];
          resolve(res.data);
        });
      });
    },

    handerPolygondata(id, allset) {
      let polygonpath = [];
      const setObj = JSON.parse(allset);
      console.log("setObj", setObj);
      const rowpolygonpath = setObj.map((itemSingle) => {
        // data 是一个多变形的数据
        // 处理一个多变形的方法
        const singlepath = this.handleSingleData(id, itemSingle);
        polygonpath.push(singlepath);
      });
      this.polygonpath = polygonpath;
    },

    handleSingleData(id, data) {
      let obj = {
        id: id,
        path: [],
        filecolor: "#000",
        strokcolor: "#409EFF",
        weight: this.strokeWeight,
        strokeopacity: this.strokeOpacity,
        fillopacity: this.fillOpacity,
      };
      const pointobj = data.lnglat.split(";");
      const pointArr = pointobj.map((point) => {
        const lnglat = point.split(",");
        return [parseFloat(lnglat[1]), parseFloat(lnglat[0])];
      });
      obj.path = pointArr;
      obj.filecolor = data.color;

      return obj;
    },

    //画点
    drawPoint(itemdata) {
      const type5 = require("@/assets/point.png");

      const myIcon = new BMapGL.Icon(type5, new BMapGL.Size(50, 110), {
        imageOffset: new BMapGL.Size(0, 0), // 设置图片偏移
      });

      // 创建标注对象并添加到地图
      const marker = new BMapGL.Marker(
        new BMapGL.Point(parseFloat(itemdata.lng), parseFloat(itemdata.lat)),
        { icon: myIcon }
      );
      marker.id = itemdata.id;
      this.map.addOverlay(marker);
    },

    // 创建一个多变形
    createOverlay(data) {
      const allset = data.set;
      const setObj = JSON.parse(allset);
      setObj.map((item) => {
        // data 是一个多变形的数据
        // 画一个多变形的方法
        this.createPolygon(item, data.id);
      });
    },

    createPolygon(data, id) {
      const pointobj = data.lnglat.split(";");
      const pointArr = pointobj.map((point) => {
        const lnglat = point.split(",");
        return new BMapGL.Point(lnglat[0], lnglat[1]);
      });

      const polygonAttribute = {
        strokeWeight: 2,
        strokeOpacity: 0.5,
        strokeColor: "#28A7E1",
        fillColor: data.color,
        fillOpacity: 1,
      };

      const overlay = new BMapGL.Polygon(pointArr, polygonAttribute);

      overlay.mode = "polygon";
      overlay.color = data.color;
      overlay.index = data.index;

      if (this.index < data.index) {
        // 更新当前的index
        this.index = data.index;
      }
      overlay.id = id;

      this.map.addOverlay(overlay);
    },
  },
};
</script>

<style lang="scss" scoped>
.plugin{
  height:0px;
  width:120px;
  display: flex;
  background: #fff;
  position: relative;
  float: left;
  top:20px;
  z-index: 1000;
  left:60px;
  .pbtn{
    width:60px;
    height:20px;
    color:#000;
    font-weight: bold;
    line-height: 20px;
    background: #fff;
    border: solid 1px #FFF;
    cursor: pointer;
  }
  .activebtn{
    color:#1384cf;
    border: solid 1px #1384cf;
  }
}


/* 隐藏国企以及版权标识 */
::v-deep .leaflet-control-attribution,
.leaflet-control {
  display: none !important;
}

.tooltipInnerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .icon {
    height: 30px;
    width: 30px;
  }
  .title {
    font-size: 16px;
    margin-left: 5px;
  }
}

.drawing-panel {
  position: absolute;
  right: 100px;
  top: 10px;
  height: 40px;
  width: 200px;
  background: "#fff";
  padding: 0px;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightlist {
  margin-top: 10px;
  width: 244px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;

  .toplist {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f0f3f8;
    padding-bottom: 10px;

    .toptitle {
      margin: 10px 0;
      width: 72px;
      height: 25px;
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 25px;
    }

    .topcon {
      width: 224px;
      background: #f0f3f8;
      border-radius: 4px;
      display: flex;
      flex-direction: column;

      .topitem {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin: 6px 0;

        .switch {
          width: 30px;
        }

        .iconbox {
          width: 156px;
          height: 52px;
          background: #ffffff;
          border-radius: 4px;
          display: flex;
          justify-content: space-around;
          align-items: center;

          .iconimg {
            width: 32px;
            height: 32px;
          }

          .iconcatename {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #43496a;
            line-height: 16px;
          }
        }
      }
    }
  }

  .bottomlist {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #f0f3f8;
    padding-bottom: 10px;
    width: 100%;
    flex: 1;

    overflow-y: scroll;

    .bottomlistinner {
      // background: red;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 15px;
      margin-top: 15px;

      .btitle {
        height: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 17px;
      }

      .bitem {
        text-align: left;
        height: 20px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 17px;
        display: block;
      }
    }
  }
}

.container4 {
  width: 100%;
  height: calc(100vh - 188px);
  color: #333;
  display: flex;
  flex-direction: row;
}

.leftmap {
  // display: flex;
  width: 98%;
  margin: 0 1%;
  height: calc(100vh - 188px);
  margin-top: 10px;
  position: relative;

  .bmap-demo {
    flex: 1;
  }
}

.labelbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  border-radius: 5px;

  .labelcontent {
    border: solid 1px #fff;
    background: #fff;
    border-radius: 4px;
    color: #333;
    font-size: 14px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 10px #06c;

    .iconimg {
      height: 30px;
      width: 30px;
    }

    .labeltext {
      margin-left: 10px;
    }

    // mar
  }

  .labeldown {
    margin-left: 15px;
    width: 0;
    height: 0;
    border-top: 10px solid #fff;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
  }
}
</style>